import React, { useState } from "react";
import { Link } from "react-router-dom";
import VillaSlides from "../../components/VillaSlides";
import { Button, Modal } from "react-bootstrap";
import Modals from "../../components/Modals";
import { AiOutlineArrowsAlt } from "react-icons/ai";
import Image from "../../components/Image";

function VillasList({
  villaImages,
  villaThumbimages,
  villaName,
  villaSlogan,
  villaPara,
  adultCount,
  villaBed,
  villaUserIcon,
  villaBedIcon,
  villaArea,
  villaAreaIcon,
  viewDetailsLink,
  detailText,
  bookNowLink,
  bookNowText,
  totalVillaAreaIcon = [],
  totalVillaArea,
  privatePoolIcon,
  privatePoolArea,
  showVillaArea = false,
  showPrivatePool = false,
  AllVillas,
  slugOfOurVillas
}) {
  const beachSliderLargeSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const beachSliderThumbSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    centerMode: false,    
    // slidesToShow: Math.min(villaThumbimages?.length, 4),
    slidesToShow: 4,
    // slidesToShow: AllVillas?.length - 1 < 4
    // ? AllVillas?.length - 1
    // : 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  const sliderClose = () => setOpen(false);
  const sliderShow = () => setOpen(true);


// Create an object to store bed counts
const bedCounts = {};

// Loop through the array and count each bed type
villaBed.forEach(bed => {
  if (bedCounts[bed]) {
    bedCounts[bed]++;
  } else {
    bedCounts[bed] = 1;
  }
});


let bedoutput = '';
for (const bedType in bedCounts) {
  bedoutput += `${bedType}: ${bedCounts[bedType]}\n`;
}
const buttonText = "Full View"
const [activeCard, setActiveCard] = useState(null);
  return (
    <>
      <div className="explore-villas d-flex flex-wrap">
        <div className="villa-details h-auto">
          <div className="villas-name">
            <Link className="text-decoration-none h4" to={viewDetailsLink}>
            <h4 className="georgia mb-3">{villaName}</h4>
            </Link>
            <span className="fw-bold villa-slogan georgia">
              <i>{villaSlogan}</i>
            </span>
            <div className="villa-para mt-xl-4 mt-2 pt-2">
              <p>{villaPara}</p>
            </div>
            <ul className="list-unstyled m-0">
              <li>
                <span className="info-icon">
                  <img
                    src={villaUserIcon}
                    alt=''
                    title=''
                  />
                </span>{" "}
                <span className="info-text">{adultCount}</span>
              </li>
              <li>
                <span className="info-icon">
                  <img src={villaBedIcon} alt='' title='' />
                </span>{" "}
             <span className="info-text" style={{display:"inline-block"}}>
                  <span style={{display:"inline-block"}}>

             </span>
              {villaBed?.map((bed,index)=>(
                <div key={index} style={{display:"inline-block"}}>
                 {index > 0 && ', '}
                  {bed.bed_count === 1 ? `${bed.bed_count} ${bed.bed_type}` : `${bed.bed_count} ${bed.bed_type}s`}
                </div>
              )
              )}
             </span>
              </li>
              <li>
                <span className="info-icon">
                  <img src={villaAreaIcon} alt='' title='' />
                </span>{" "}
                <span className="info-text">{villaArea}m²</span>
              </li>
              {showVillaArea ? (
                <li>
                  <span className="info-icon">
                    <Image
                      src={totalVillaAreaIcon}
                      alt=''
                      title=''
                    />
                  </span>{" "}
                  <span className="info-text">{totalVillaArea}</span>
                </li>
              ) : (
                ""
              )}
              {showPrivatePool ? (
                <li>
                  <span className="info-icon">
                    <img
                      src={privatePoolIcon}
                      alt=''
                      title=''
                    />
                  </span>{" "}
                  <span className="info-text">{privatePoolArea}</span>
                </li>
              ) : (
                ""
              )}
            </ul>
            <div className="villa-actions mt-xl-5 mt-4">
              <Link
                className="btn primary theme-btn me-3 dark-btn fw-medium text-white text-uppercase border-theme"
                to={viewDetailsLink}
              >
                {detailText}
              </Link>
              <Button
                onClick={handleShow}
                className="btn primary theme-btn fw-medium text-white text-uppercase border-theme book_now_villas_ga4"
                to={bookNowLink}
              >
                {bookNowText}
              </Button>
              <Modals show={show} handleClose={handleClose} />
            </div>
          </div>
        </div>
        {/* /view details */}

        <div className="villas-slides position-relative">
          <VillaSlides
            villaThumbimages={villaThumbimages}
            villaImages={villaImages}
            beachSliderThumbSettings={beachSliderThumbSettings}
            beachSliderLargeSettings={beachSliderLargeSettings}
          />
{/* 
          <div className='full-slide-view position-absolute'>
            <Button onClick={sliderShow} className='btn btn-primary full-btn border-0 smooth text-nowrap'><AiOutlineArrowsAlt className='me-1' /> {buttonText}</Button>
          </div> */}
          
          <Modal className='gallery-slide-modal villa-slide-modal slick-arrow-default' size="xl" show={open} onHide={sliderClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <VillaSlides villaImages={villaImages} beachSliderLargeSettings={beachSliderLargeSettings} />
            </Modal.Body>
          </Modal>

        </div>
        {/* villas slides */}
      </div>
    </>
  );
}

export default VillasList;
